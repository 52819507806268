import bgimage from "../../assets/img/bg-college.jpg";
import bgimage1 from "../../assets/img/background1.jpg";
import bgimage2 from "../../assets/img/background2.jpg";

// const colorRed = "#d23139";
const colorWhite = "#ffffff";

const styles = (theme) => ({
  root: {
    ':-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        backgroundColor: 'red !important'
    }
  },
  containerLogin: {
    alignItems: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    transition: 'background-image 0.8s ease-in-out',
    width: "100%",
  },
  background1: {
    backgroundImage: `url(${bgimage})`,
  },
  background2: {
    backgroundImage: `url(${bgimage1})`,
  },
  background3: {
    backgroundImage: `url(${bgimage2})`,
  },
  bgcolorwhite: {
    background: colorWhite,
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
    },
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  input: {
    WebkitBoxShadow: "0 0 0 1000px white inset",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    }
  }
});

export default styles;
