import { createMuiTheme } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";

export default createMuiTheme({
  palette: {
    primary: {
      main: red[600],
    },
    secondary: {
      main: red[900],
    },
  },
  overrides: {
    MuiInput: {
      root: {
        "&$disabled": {
          color: grey[700],
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$disabled": {
          color: grey[700],
        },
      },
    },
  },
  typography: {
    fontFamily:
      "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
    h1: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "1.35rem",
      fontWeight: 500,
    },
  },
});
