import { create } from "./api";

export async function postSignIn(values) {
  const axios = create();
  return await axios.post("/api/v1/signin", values);
}

export async function postLogIn(values) {
  const axios = create();
  return await axios.post("/api/v1/login", values);
}

export async function postVerify(values) {
  const axios = create();
  return await axios.post("/api/v1/verify", values);
}

export async function postMailVerify(values) {
  const axios = create();
  return await axios.post("/api/v1/mail-verify", values);
}
