import Axios from "axios";
import { FormikHelpers } from "formik"; // eslint-disable-line no-unused-vars

/**
 * Base Axios API factory.
 *
 * @param {AxiosRequestConfig} options
 */
export function create(options) {
  const apikey = process.env.REACT_APP_APIKEY;
  return Axios.create({
    ...options,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apikey
    }
  });
}

/**
 * @param {AxiosError} e
 * @param {FormikHelpers} formikHelpers
 */
export function apiErrorHandler(e, formikHelpers) {
  const {response} = e;

  if (response && response.data && response.data.responseCode) {
    return handleWebServiceResponse(response.data, formikHelpers);
  }

  if (e.response) {
    handleDataError(e.response, formikHelpers);
    return;
  }

  if (e.message) {
    handleMessageError(e, formikHelpers);
    return;
  }
}

function handleWebServiceResponse(response, formikHelpers) {
  const { setStatus } = formikHelpers;
  const {error, responseCode} = response;

  if (error.message) {
    setStatus(error.message);
  }

  return responseCode;
}

/**
 * Error message handler.
 *
 * @param {Error} error
 * @param {FormikHelpers} formikHelpers
 */
 function handleMessageError(error, formikHelpers) {
  const { setStatus } = formikHelpers;
  const { message } = error;

  switch (message) {
    case "Network Error":
      setStatus(`
        Error de conexión, imposible contactar al servidor.
        Revise que su conexión esté activa. Si el problema persiste contacte al administrador.`);
      break;

    default:
      setStatus(message);
      break;
  }
}

/**
 * Error reponse handler.
 *
 * @param {AxiosResponse<any>} response
 * @param {FormikHelpers} formikHelpers
 */
function handleDataError(response, formikHelpers) {
  const { setFieldError, setStatus } = formikHelpers;

  setStatus(null);

  if (response.data) {
    console.log(response.data);

    const { message, ...errors } = response.data;

    if (message) {
      setStatus(message);
      return;
    }

    Object.keys(errors).forEach((i) => {
      const error = errors[i];

      error.forEach((e) => setFieldError(i, e));
    });
  }
}
