import {
  Box,
  Button,
  CircularProgress,
  Container,
  withStyles
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { apiErrorHandler } from '../../utils/api';
import { isDev } from '../../utils/environment';
import { postLogIn, postMailVerify, postSignIn, postVerify } from "../../utils/login";
import styles from "./login-styles";

const STEPS = {
  EMAIL: 0,
  FULLNAME: 1
}

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="textSecondary" align="center">
        {" © "} {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://uiwbajio.mx/">
          Universidad Incarnate Word Campus Bajío
        </Link>
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Todos los derechos reservados{"."}
      </Typography>
      <Box mt={2}>
        <Typography variant="body2" color="textSecondary" align="center">
          <Link color="inherit" href="https://uiwbajio.mx/privacidad/" target="_blank">
            Aviso de privacidad
        </Link>
        </Typography>
      </Box>
    </React.Fragment>
  );
}

function Login(props) {
  const { classes } = props;

  const [counter, setCounter] = useState(0);
  const [step, setStep] = useState(STEPS.EMAIL);
  const [confirmation, setConfirmation] = useState(null);

  const backgrounds = [
    classes.background1,
    classes.background2,
    classes.background3
  ]

  useEffect(() => {
    let hash = window.location.hash || '';
    if (hash.includes('#confirmation')) {
      hash = hash.replace("#confirmation=", "");
      setConfirmation(hash);
      toast.warning(
        "Ingrese su correo para confirmar su cuenta.",
        { autoClose: false }
      )
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => setCounter(counter => (counter + 1) % 3), 6000);
    return () => clearInterval(interval);
  }, []);

  const validation = Yup.object({
    email: Yup.string()
      .email("Correo electrónico inválido.")
      .required("Campo requerido")
      .email()
      .matches(
        /.*(uiwbajio.mx|uiw.edu.mx)$/,
        "Dominio inválido."
      )
    ,
    ...(!isDev()) && {
      recaptcha: Yup.string()
        .typeError("La validación de usuario es requerida")
        .required("La validación de usuario es requerida")
    },
    fullname: (step > STEPS.FULLNAME) && Yup.string()
      .required()
      .min(3, "Ingrese su nombre completo")
  });

  const submitHandler = async (values, helpers) => {
    const { setSubmitting, setStatus } = helpers;

    const data = {
      fullname: values["fullname"],
      email: values["email"],
      hash: confirmation
    }

    let response = null;

    if (step === STEPS.EMAIL) {
      try {
        if (!confirmation) {
          response = await postVerify(data);
        }

        if (confirmation) {
          response = await postMailVerify(data);
        }

        response = await postLogIn(data);

        const result = response && response.data && response.data.data;

        if (result && result.location) {
          setTimeout(() => {
            window.location.href = result.location;
          }, 1500);

          toast.success("Inicio de sesión correcto. Redireccionando...");

          return;
        }
      } catch (e) {
        const responseCode = apiErrorHandler(e, helpers);

        if (responseCode && (responseCode === "USER_NOT_FOUND" || responseCode === "USER_NOT_CONFIRMED")) {
          setStep(STEPS.FULLNAME);
          setConfirmation(null);
          return;
        }

        toast.error("Error al iniciar sesión. Si el problema persiste contacte al administrador.");
      }
    }

    // STEPS.FULLNAME
    try {
      response = await postSignIn(data);

      toast.info(
        "Se ha enviado un correo de confirmación a su cuenta de correo electrónico. Confirme su cuenta mediante el enlace que se le ha enviado.", {
        autoClose: false
      });

      setStep(STEPS.EMAIL);
      setStatus(null);
    } catch (e) {
      apiErrorHandler(e, helpers);
      toast.error("Error al iniciar sesión. Si el problema persiste contacte al administrador.");
      setSubmitting(false);
    }
  };

  return (
    <div className={clsx(classes.containerLogin, backgrounds[counter])}>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.bgcolorwhite}
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Box mb={2}>
            {!confirmation && <Typography component="h1" variant="h5">
              Entrar a mi cuenta Worcket
          </Typography>}
            {confirmation && <Typography component="h1" variant="h5">
              Confirmar mi cuenta Worcket
          </Typography>}
          </Box>

          <Formik
            initialValues={{ email: "", recaptcha: null, }}
            validationSchema={validation}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, status, setFieldValue }) => {
              return (
                <Form className={classes.form}>
                  {status && (
                    <Box mt={4} mb={2}>
                      <Alert variant="outlined" severity="error">
                        {status}
                      </Alert>
                    </Box>
                  )}

                  <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Correo Electrónico"
                    helperText="El dominio del correo debe pertenecer a UIW Bajío."
                    name="email"
                    type="email"
                    autoComplete="off"
                    autoFocus
                    disabled={STEPS.FULLNAME === step}
                  />

                  {(STEPS.FULLNAME === step) && <Field
                    component={TextField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nombre completo"
                    helperText="Ingrese su nombre completo"
                    name="fullname"
                    type="text"
                    autoComplete="off"
                    autoFocus
                  />}

                  <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.captchaContainer}
                  >
                    {!isDev() &&
                      <ReCAPTCHA
                        hl="es-419"
                        sitekey="6Lc01XwaAAAAAJY8-IQGi-tt9uiXUhhFywNFLruP"
                        onChange={(token) => {
                          setFieldValue("recaptcha", token);
                        }}
                      />
                    }
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <ErrorMessage
                      name="recaptcha"
                      component={Alert}
                      severity="error"
                    />
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <CircularProgress color="inherit" size={24} />
                    )}
                    {!isSubmitting && "Entrar"}
                  </Button>

                </Form>
              );
            }}
          </Formik>
        </div>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(Login);
