import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React from "react";
import { ToastContainer } from 'react-toastify';
import theme from "../theme/createTheme";
import 'react-toastify/dist/ReactToastify.css';
// import Home from "./pages/home";
import Login from "./pages/login";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Login />
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}

export default App;
